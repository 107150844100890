import * as React from "react"
import Menu from "../../components/menu"
import Footer from "../../components/footer"
import { subtitle, title, requiredForm, input } from "../../styles"
import clsx from "clsx"
import Seo from "../../components/seo"

// markup
const ContactPage = () => {
  const style = clsx(subtitle, "mb-2");
  return (
    <div>
      <Seo>Contact Me</Seo> 
      <Menu/>
        <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:gap-8 md:grid-cols-3">
          <div className="max-w-screen-sm mt-12 md:pt-32">
            <h1 className= {clsx(title, "mb-4")}>
              Contact
            </h1>
            <div className="text-left font-sans text-sm font-normal text-battleship-grey my-2 w-4/6 md:w-full mx-auto">
              <p>  You want to know more about me or my work, or you have a cool project that you want me to dive into. </p>
              <p className="mb-4">Don’t hesitate to contact me.</p>
              <h2 className = { style }> Email</h2>
              <a className="block mb-2" href="mailto:charlotte@botermans.be">charlotte@botermans.be</a>
              <h2 className = { style }> Location</h2>
              <p>Brussels, Belgium based but open for remote work.</p>
            </div>
          </div>
          <form method="post" className="col-span-2" action="/contact/success" netlify netlify-honeypot="bot-field" data-netlify="true" name="contact">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="grid grid-cols-2 gap-4 auto-rows-min mt-12 md:pt-32">
              <div className="col-span-2 lg:col-span-1">
                <label htmlFor="first_name"><span className={ subtitle }>First name</span> <span className= { requiredForm }>(required)</span></label>
                <input type="text" id="first_name" name="first_name" autoComplete="off" className={ input } required/>
              </div>
              <div className="col-span-2 mt-10 lg:col-span-1 lg:mt-0">
                <label htmlFor="last_name"><span className={ subtitle }>Last name</span> <span className={ requiredForm }>(required)</span></label>
                <input type="text" id="last_name" name="last_name" autoComplete="off" className={ input } required/>
              </div>
              <div className="col-span-2 mt-10">
                <label htmlFor="email"><span className={ subtitle }>Email</span> <span className={ requiredForm }>(required)</span></label>
                <input id="email" name="email" autoComplete="off" className={ input } type="email" required/>
              </div>
              <div className="col-span-2 mt-10">
                <label htmlFor="phone"><span className={ subtitle }>Phone</span></label>
                <input type="text" id="phone" name="phone" autoComplete="off" className={ input } />
              </div>
              <div className="col-span-2 mt-10">
                <label htmlFor="subject"><span className={ subtitle }>Subject</span></label>
                <input type="text" id="subject" name="subject" autoComplete="off" className={ input } />
              </div>
              <div className="col-span-2 mt-10">
                <label htmlFor="message"><span className={ subtitle }>Message</span> <span className={ requiredForm }>(required)</span></label>
                <textarea type="text" id="message" name="message" autoComplete="off" className={ input } required/>
              </div>
              <div className="col-span-2 mt-2">
                <button className="bg-lavender font-sans text-dark-lavender text-sm rounded-md h-10 px-6" type="submit">Send</button>
              </div>
            </div>
          </form>
        </div>
      <Footer/>
    </div>
     
  
  )
}


export default ContactPage 
